import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: 992px) {
    margin-left: -10rem;
    margin-top: 8rem;
  }
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

const Content = styled(ParseContent)`
  background-color: ${({ theme }) => theme.color.primary};
  & h1,
  & h2 {
    margin-bottom: 1.25rem;
    @media (min-width: 992px) {
      font-size: 40px;
    }
    @media (max-width: 991px) {
      line-height: 40px;
    }
  }
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 28px;
    }
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
  @media (min-width: 992px) {
    padding: 3rem 7rem 3rem 3rem;
  }
  @media (max-width: 991px) {
    padding: 2rem 4rem 2rem 2rem;
  }
`

const PersonImage = styled(Plaatjie)`
  @media (min-width: 992px) {
    right: -150px;
    height: 360px;
    width: 300px;
    bottom: 0;
  }
  @media (max-width: 991px) {
    bottom: -2px;
    left: -190px;
    height: 260px;
    width: 200px;
  }
  @media (max-width: 767px) {
    left: unset;
    right: 0;
    height: 260px;
    width: 200px;
  }
  @media (max-width: 575px) {
    height: 180px;
    width: 150px;
    bottom: 0;
  }
  @media (max-width: 375px) {
    height: 120px;
    width: 100px;
  }
`

interface CtaPersonProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const CtaPerson: React.FC<CtaPersonProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          {fields.title && (
            <Title content={fields.title} className="mb-lg-5 pb-4" />
          )}
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <Plaatjie image={fields.image} alt="" />
            </div>
            <div className="col-md-5 col-lg-3">
              <ContentWrapper className="position-relative">
                <Content content={fields.description} />
                <PersonImage
                  image={fields.personimage}
                  alt=""
                  className="position-absolute"
                />
              </ContentWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CtaPerson
