import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: 992px) {
    margin-right: -10rem;
    margin-top: 14rem;
  }
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

const Content = styled(ParseContent)<{ hasBeam: boolean }>`
  background-color: ${({ theme }) => theme.color.primary};
  & h1,
  & h2 {
    margin-bottom: 1.25rem;
    @media (min-width: 992px) {
      ${(props) =>
        props.hasBeam
          ? css`
              font-size: 48px;
            `
          : css`
              font-size: 40px;
            `}
    }
    @media (max-width: 991px) {
      line-height: 40px;
    }
  }
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 28px;
    }
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
`

const Beam = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 215px;
    background-color: ${({ theme }) => theme.color.grey};
  }
`

interface CtaDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const CtaDefault: React.FC<CtaDefaultProps> = ({ fields }) => (
  <section className={`position-relative ${!fields.beam ? 'mb-5' : ''}`}>
    <div className="container py-lg-5">
      <div
        className={`row ${
          fields.beam ? 'justify-content-end' : 'justify-content-center'
        } `}
      >
        <div className="col-lg-10">
          {fields.title && (
            <Title content={fields.title} className="mb-lg-5 pb-4" />
          )}
          <div className="row">
            <div
              className={`${
                fields.beam ? 'col-md-5 col-lg-3' : 'col-md-6 col-lg-4'
              } `}
            >
              <ContentWrapper>
                <Content
                  content={fields.description}
                  className="p-4 p-sm-5"
                  hasBeam={fields.beam}
                />
              </ContentWrapper>
            </div>
            <div
              className={`${
                fields.beam ? 'col-md-7 col-lg-9' : 'col-md-6 col-lg-8'
              } `}
            >
              <Plaatjie image={fields.image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {fields.beam && <Beam />}
  </section>
)

export default CtaDefault
